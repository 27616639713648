import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../Pages/Home";
import PageNotFound from "../Pages/PageNotFound";
import SeeAll from "../Pages/SeeAll";
import ErrorPage from "../Pages/Error";


const Routing=()=>{
    return(<>
        <BrowserRouter>
            <Routes>
                <Route path="/" exact={true} element={<Home/>}></Route>
                <Route path="/seeAll" exact={true} element={<SeeAll/>}></Route>
                <Route path="*" exact={true} element={<PageNotFound/>}></Route>
                <Route path="/error" exact={true} element={<ErrorPage/>}></Route>
            </Routes>
        </BrowserRouter>
    </>)
}
export default Routing;